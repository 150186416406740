import React from "react"
// import { StaticQuery, graphql } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

import { Container, Col, Row } from "react-bootstrap"
// import { Modal, Button, Container, Col, Row, Card } from "react-bootstrap"

import MyStyles from "./servizi_content.module.css"


const IndexServizi = () => (
    <StaticQuery
      query={graphql`
        {
          img_bg_1: file(relativePath: {eq: "index/Overlay/A.svg"}) {
            publicURL
          }
          img_bg_2: file(relativePath: {eq: "index/Overlay/B.svg"}) {
            publicURL
          }
          img_bg_3: file(relativePath: {eq: "index/Overlay/C.svg"}) {
            publicURL
          }
          img_bg_4: file(relativePath: {eq: "index/Overlay/D.svg"}) {
            publicURL
          }
        }
      `}
      render={data => (
        <>
          <Container fluid className="vh-100">
            {/* <Col xs={12} sm={12} md={4} >
              <Row>
                <a href="https://www.archimede-srl.com/"
                  style={{
                    width: `100%`
                  }}
                >
                  <Image src={ `${data.img_bg_1["publicURL"]}#svgView(viewBox(0, 0, 125, 125)) ` } fluid /> 
                </a>
              </Row>
            </Col> */}
            <Row className="h-100 align-items-center no-gutters p-4">

              <Col xs={12} sm={6} md={6} lg={3} className={ `${MyStyles.my_margin} `}>
                <a href="https://www.archimede-srl.com/" style={{
                    width: `100%`,
                    height: `100%`
                  }}>
                <div>
                <object type="image/svg+xml" id="logox1" data={ `${data.img_bg_1["publicURL"]}` } >
                  <div>SVG logo</div>
                </object>
                </div>
                </a>
              </Col>
              <Col xs={12} sm={6} md={6} lg={3} className={ `${MyStyles.my_margin} `}>
                <object type="image/svg+xml" id="logox2" data={ `${data.img_bg_2["publicURL"]}` } >
                  <div>SVG logo</div>
                </object>
              </Col>
              <Col xs={12} sm={6} md={6} lg={3} className={ `${MyStyles.my_margin} `}>
                <object type="image/svg+xml" id="logox3" data={ `${data.img_bg_3["publicURL"]}` } >
                  <div>SVG logo</div>
                </object>
              </Col>
              <Col xs={12} sm={6} md={6} lg={3} className={ `${MyStyles.my_margin} `}>
                <object type="image/svg+xml" id="logox4" data={ `${data.img_bg_4["publicURL"]}` } >
                  <div>SVG logo</div>
                </object>
              </Col>

            </Row>
          </Container>
        </>
      )}
    />
  )
  export default IndexServizi