import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layouts/layout_servizi"
import SEO from "../components/seo"
import Header4 from "../components/contents/header4"


import ServiziContent from "../content/index/servizi_content"
import ServiziIndexPage from "../content/servizi/index"

// const ServiziPage = ({ data }) => (
//     <>
//       <SEO title="Home" />
//       <div>
//         <ServiziContent />
//         </div>
//     </>
    
// )



const ServiziPage = ({ data, location }) => {
    /* console.log(data.site, data.site.siteMetadata.lavora_con_noi), */
    
  
    return (
      <Layout
        header={
          <Header4
          src_sfondo={data.file.childImageSharp.fluid}
          title={data.site.siteMetadata.servizi.title}
          link_page={data.site.siteMetadata.servizi.link_page}
        />
        }
        location={location}
      >
        <SEO
          title={data.site.siteMetadata.servizi.title}
          pathname={data.site.siteMetadata.servizi.link_page}
        />
  
        <div id="form" className="mt-n5 pt-5" />
  
        <ServiziIndexPage />
      </Layout>
    )
  }


export const query = graphql`
{
    file(relativePath: { regex: "/^servizi\/header/" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        servizi {
          title
          seo_title
          link_page
        }
      }
    }
  }
`


export default ServiziPage