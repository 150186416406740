import React from "react"
// import { StaticQuery, graphql } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

import { Container, Col, Row } from "react-bootstrap"
// import { Modal, Button, Container, Col, Row, Card } from "react-bootstrap"

//import MyStyles from "./servizi_content.module.css"
import ServiziContent from "../index/servizi_content"


const IndexPageServizi = () => (
    <StaticQuery
      query={graphql`
        {
          img_bg_1: file(relativePath: {eq: "index/Overlay/A.svg"}) {
            publicURL
          }
          img_bg_2: file(relativePath: {eq: "index/Overlay/B.svg"}) {
            publicURL
          }
          img_bg_3: file(relativePath: {eq: "index/Overlay/C.svg"}) {
            publicURL
          }
          img_bg_4: file(relativePath: {eq: "index/Overlay/D.svg"}) {
            publicURL
          }
        }
      `}
      render={data => (
        <>
            <Container fluid>
            <ServiziContent />
            {/* <Row xs="auto">
                <Col></Col>
                <Col>2 of 3</Col>
                <Col>3 of 3</Col>
            </Row> */}
            </Container>
            <div></div>
            <div></div>
            
            
        </>
      )}
    />
  )
  export default IndexPageServizi