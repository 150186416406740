/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
/* import { useStaticQuery, graphql } from "gatsby" */

import NavBar from "./navBar"
/* import Header from "./header" */
// import Footer from "./footer"
// import "./layout.css"

const Layout = ({ header, children, location }) => {
  /* const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `) */

  return (
    <>
      <NavBar location={location} />

      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      
      {header}

      <div
       
      >

          <main>
            {children}
          </main>


      </div>

      
        
        
       
      {/* <Footer/>   */}
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
      
    </>
  )
}





Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.element,
}

export default Layout
